import Penguin1 from "../images/reference/1.png"
import Penguin2 from "../images/reference/2.png"
import Penguin3 from "../images/reference/3.png"
import Penguin4 from "../images/reference/4.png"
import Penguin5 from "../images/reference/5.png"

export const TIMELINE_PANS = [
  {
    img: Penguin1,
    text: "Prvotní schůzka",
  },
  {
    img: Penguin2,
    text: "Analýza konkurence",
  },
  {
    img: Penguin3,
    text: "Uživatelský výzkum",
  },
  {
    img: Penguin4,
    text: "Mapa obsahu",
  },
  {
    img: Penguin5,
    text: "Wireframy",
  },
]
