import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import { Cubes, Medailon, PaddingWrapper } from "@igloonet-web/shared-ui"
import Wireframe from "../../../images/reference/panvicky/wireframe1.png"
import Pans from "../../../images/reference/panvicky/pans.png"
import Logo from "../../../images/reference/panvicky/logo-panvicky.png"
import Design from "../../../images/reference/panvicky/design.png"
import Hak from "../../../images/team/mini/levejhak.jpg"
import Sobol from "../../../images/team/mini/sobol.jpg"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"
import PenguinsTimeline from "../../../components/reference-detail/penguins-timeline"
import { TIMELINE_PANS } from "../../../model/reference-timeline-pans"

class Panvicky extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Nový e-shop pro Panvicky.cz | igloonet</title>
          <meta
            name="description"
            content="Reference vytvoření nového e-shopu pro panvicky.cz, specialistu na kuchyňské nádobí."
          />
        </Helmet>

        <ReferenceHeader
          companyName="pánvičky.cz"
          heading="Vytvoření e-shopu pro www.panvicky.cz"
          date="červen 2017 - říjen 2021"
        />

        <ReferenceImageText
          right
          img={Pans}
          eager
          alt=""
          logo={Logo}
          logoAlt="logo společnosti panvicky.cz"
          link="//panvicky.cz/"
        >
          <p>
            Prvotním zadáním bylo vytvořit nový e-shop, přiblížit se zákazníkovi
            a nepřímo podpořit navýšení obratu obchodu.
          </p>
          <h2>Cíle e-shopu</h2>
          <ul>
            <li>Zjednodušit proces nákupu.</li>
            <li>
              Zpřehlednit nabídku produktů pro jednotlivé cílové skupiny
              zákazníků.
            </li>
            <li>Jednoduchá správa e-shopu ze strany klienta.</li>
          </ul>
        </ReferenceImageText>

        <PaddingWrapper>
          <ReferenceImageText
            halfImgSize
            responsiveTextOrder
            img={Wireframe}
            alt=""
          >
            <h2>Analýza a wireframy</h2>
            <p>
              Na základě analýzy konkurence, základního uživatelského výzkumu a
              konzultace s klientem, jsme vytvořili wireframy pro nový e-shop.
            </p>
            <p>
              Graficky kvalitně zpracované wireframy, tedy skicy webu s
              rozložením funkčních prvků, nám umožňují zapojit klienta do
              procesu výroby webové aplikace.
            </p>
            <Cubes
              data={[
                {
                  description: "Analýza konkurence",
                  number: "+",
                },
                {
                  description: "Uživatelský výzkum",
                  number: "+",
                },
                {
                  description: "Hodiny nad wireframy",
                  number: "+",
                },
              ]}
            />
          </ReferenceImageText>
        </PaddingWrapper>

        <PenguinsTimeline data={TIMELINE_PANS} />

        <PaddingWrapper>
          <Medailon personName="Petr Pitucha" position="UX a grafika" img={Hak}>
            <p>
              Mapu obsahu a wireframy jsme tvořili na základně získaných dat z
              analytické části. Při návrhu designu tedy nemyslíme jen na
              estetickou část, ale především na tu funkční.
            </p>
          </Medailon>
        </PaddingWrapper>

        <ReferenceImageText halfImgSize right img={Design} alt="">
          <h2>Design</h2>
          <p>
            V igloo máme rádi vyvážení vizuálně <b>atraktivního designu</b> s
            uživatelskou přívětivostí.
          </p>
          <p>
            Grafika musí návštěvníka zaujmout neotřelým designem, ale nemůžeme
            zapomenout na <b>UX</b> a důvody, proč vlastně na stránky přišel.
          </p>
        </ReferenceImageText>

        <PaddingWrapper>
          <Medailon
            personName="Jan Nobilis"
            position="Vedení projektu"
            img={Sobol}
          >
            <p>
              Klient si vybral pro realizaci svého e-shopu igloonet kvůli
              spojení vývoje a marketingu. Hledal partnera se širokým záběrem,
              který pro něj nejen vyvine e-shop, ale zároveň pomůže s prodejem
              jeho produktů.
            </p>
          </Medailon>
        </PaddingWrapper>

        <ReferenceImageText>
          <h2>Shrnutí</h2>
          <p>
            Na základě analýz jsme vytvořili jasně zaměřený e-shop s velkým
            důrazem na zákazníka. Na práce na vývoji navázali kolegové z
            marketingu a pomohli Pánvičkám vytvořit marketingovou strategii, v
            rámci které teď řešíme PPC a mailingové kampaně. Aktuálně se
            plánujeme zaměřit na posilování značky a přípravy na hlavní sezónu.
          </p>
        </ReferenceImageText>

        <ContactForm contact="sobol" />
      </Layout>
    )
  }
}

export default Panvicky
